<script>
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { nextTick, reactive } from 'vue'
import { useNamespacedEventListener } from 'vue_features/shared/composables'

const useGlobalDropdown = defineStore('globalDropdown', () => {
  const state = reactive({ openId: null, closeFn: null })

  const { on: bodyOn, off: bodyOff } = useNamespacedEventListener(document.body)
  const { on: winOn, off: winOff } = useNamespacedEventListener(window)
  const CLICK = 'click.dropdown'
  const RESIZE = 'resize.dropdown'

  const removeListeners = () => {
    bodyOff(CLICK)
    winOff(RESIZE)
  }
  const closeGlobal = () => {
    state.closeFn()
    removeListeners()
  }
  const openGlobal = (id, onResize, onClose) => {
    if (state.openId && state.openId !== id) closeGlobal()

    state.openId = id
    state.closeFn = onClose

    winOn(RESIZE, onResize)
    if (onClose) bodyOn(CLICK, onClose, { once: true })
  }

  return { state, openGlobal, removeListeners }
})
</script>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'

const { openGlobal, removeListeners } = useGlobalDropdown()
const props = defineProps({
  id: { type: String, required: true },
  buttonClasses: { type: Array, default: () => [] },
  buttonAria: { type: String, default: null },
  dropdownClasses: { type: Array, default: () => [] },
  closeOnClick: { type: Boolean, default: true },
  openDropdown: { type: Boolean, default: false },
  defaultBtnClass: { type: String, default: 'btn' },
  disabled: { type: Boolean, default: false },
})
const emit = defineEmits(['dropdown-toggled'])

const isOpen = ref(false)
const flowLeft = ref(false)

const fullDropdownClasses = computed(() => {
  const classes = ['dropdown-pane', 'vue-dropdown', ...props.dropdownClasses]
  if (isOpen.value) classes.push('is-open')
  if (flowLeft.value) classes.push('flow-left')
  return classes
})
const fullButtonClasses = computed(() => [props.defaultBtnClass, ...props.buttonClasses])

const close = () => {
  if (!isOpen.value) return
  isOpen.value = false
  emit('dropdown-toggled', isOpen.value)
  removeListeners()
}
const open = () => {
  if (isOpen.value) return
  isOpen.value = true
  emit('dropdown-toggled', isOpen.value)
}
const toggle = () => (isOpen.value ? close() : open())
const updateAlignment = () => {
  const element = document.getElementById(props.id)
  const buffer = 20

  flowLeft.value = false
  if (!element) return

  nextTick(() => {
    const elementLeft = element.getBoundingClientRect().left
    flowLeft.value = window.innerWidth < elementLeft + element.offsetWidth + buffer
  })
}

watch(isOpen, (openNow) => {
  if (!openNow) return
  updateAlignment()
  openGlobal(props.id, updateAlignment, props.closeOnClick ? close : null)
})
watch(
  () => props.openDropdown,
  (openNow) => openNow && open(),
  { immediate: true },
)

onMounted(updateAlignment)
onBeforeUnmount(removeListeners)
</script>

<template>
  <slot
    v-bind="{
      ...$attrs,
      id,
      fullButtonClasses,
      buttonAria,
      isOpen,
      fullDropdownClasses,
      closeOnClick,
      open,
      close,
      toggle,
      disabled,
    }"
  />
</template>
