<template>
  <ul class="lzui-nav-tabs vue-tabs" :class="$attrs.class">
    <slot></slot>
    <li v-if="isSlotUsed($slots, 'overflow')" class="lzui-nav-tabs__overflow">
      <button class="lzui-nav-tabs__overflow__toggle" type="button" @click="toggleOverflow">
        {{ $t('common.more') }} +
      </button>
      <ul :class="{ is_open: isOverflowVisible }" class="lzui-nav-tabs__overflow__tabs">
        <slot name="overflow"></slot>
      </ul>
    </li>
  </ul>
  <slot name="panels" v-bind="{ activateTab }"></slot>
</template>

<script setup>
import each from 'lodash/each'
import find from 'lodash/find'
import { reactive, provide, onMounted, ref } from 'vue'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

const emit = defineEmits(['activated-tab'])
const tabs = reactive({})
const isOverflowVisible = ref(false)
const toggleOverflow = () => (isOverflowVisible.value = !isOverflowVisible.value)

const registerTab = (target, tab) => (tabs[target] = tab)
provide('registerWithTabParent', registerTab)

const activateTab = (target) => tabs[target]?.activate()

const tabActivated = (target) => {
  each(tabs, (tab, key) => {
    if (key !== target) tab.deactivate()
  })

  isOverflowVisible.value = false
  emit('activated-tab', target)
}
provide('tabActivated', tabActivated)

const activateInitial = () => {
  let active
  active = find(tabs, (tab) => !!tab.initial)

  if (!active) active = tabs[Object.keys(tabs)[0]]
  each(tabs, (tab) => (tab === active ? tab.activate() : tab.deactivate()))
}
onMounted(activateInitial)
</script>
